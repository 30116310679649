<template>
    <div class="w-full">
        <div v-if="!static" class="mb-2 color-green cursor-pointer" @click="show = !show">{{ show ? 'Hide detectors' : 'Show detectors' }}</div>

        <div v-if="show" v-html="templateDetector(value)"></div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => { return [] },
        },
        static: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            show: this.static,
        }
    },

    methods: {
        templateDetector(detectors) {
            if (!detectors.length) {
                return '';
            }

            const result = [];

            detectors.forEach(item => {
                const value = [];

                for (let key in item.additional_data) {
                    if (key.includes('legacy_addservice')) {

                        value.push(`${key} = ${item.additional_data[key]}`);

                        continue;
                    }

                    for (let _key in item.additional_data[key]) {
                        value.push(`${ key} ${_key} = ${item.additional_data[key][_key]}`);
                    }
                }

                const toHtml = `<span class="text-gray-700 mr-1">${item.name}: </span> ${value.join(', ')}`;

                result.push(toHtml);
            });

            return result
                .map(item => {
                    return `
                        <div class="mb-1">${item}</div>
                    `
                })
                .join('');
        },
    }
}
</script>

<style>

</style>